


/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgb(100,100,100) rgb(170,170,170);
}
  
/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}
  
*::-webkit-scrollbar-track {
    background: rgb(170, 170, 170);
}
  
*::-webkit-scrollbar-thumb {
    background-color: rgb(100, 100, 100);
    border-radius: 20px;
    border: 3px solid rgb(170, 170, 170);
}


section{
    padding: 5px;
    margin: 5px 10px;
    height: auto;
    

}

a{
    text-decoration: none;
}
button{
    background-color: rgb(255, 255, 255);
    //color: white;
    border-style: none;
    font-weight: 600;
    color: rgb(53, 124, 255);
    padding: 6px;
    font-size: 16px;
    //background-color: rgb(100,255, 100);
    border-radius: 5px;
    margin: 4px;
    &:hover{
        cursor: pointer;
        background-color: rgb(53, 124, 255);
        color: white;
    }
}
.logo-section{
    background-color: rgb(53, 124, 255);
    align-items: center;
    padding: 5px 35px;
    justify-content: space-between;
    color: white;
    flex-wrap: wrap;
}
.awd-logo{
    svg{
        height: 200px;
    }
    
    color: white
}
.second-section{
    background-color: rgb(219, 219, 219);
    justify-content: space-between;
    padding: 5px 35px;
    flex-wrap: wrap;
}
.sign-out{
    cursor: pointer;
}
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
.flex{
    display: flex;
}
.main{
    display: flex;
    padding: 20px;
    font-family: "roboto";
}
.selector{
    width: 250px;
    background-color: rgb(219, 219, 219);
    border-radius: 10px;
    .collection{
        width: auto;
        margin: 5%;
        padding: 4px 4px;
        margin: 5px 10px;
        color: black;
        font-size: 20px;
        background-color: white;
        border-radius: 5px;
        display: block;
        &:hover{
            border-width: 0;
            border-left-width: 5px;
            border-style: solid;
            border-color: rgb(53, 124, 255);
        }
    }
    .active{
        
        border-width: 0;
        border-left-width: 5px;
        border-style: solid;
        border-color: rgb(53, 124, 255);
    }
}
.third-section{
    width: calc(100% - 120px);
    margin: 30px;
    padding: 30px;
    background-color: rgb(219, 219, 219);
    border-radius: 10px;
}
.sample-site{
    background-color: white;
    width: 100%;
    height: 90vh;
    
    border-width: 0px;
}
.editor{
    width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    background-color: rgb(219, 219, 219);
    border-radius: 10px;
    padding: 10px;
    h1{
        //color: white;
        //padding: 20px;
        font-size: 32px;
    }
    .topbar{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    h2{
        font-size: 30px;
    }

    .new-item{
        //min-height: 30px;
        width: calc(100%-5px);
        
        //padding: 5px;
        background-color: rgb(255, 255, 255);
        color: black;
        border-radius: 5px;
        //margin: 5px 0;
        display: inline-flex;
        span{
            padding: 5px;
            font-size: 20px;
            
            //font-weight: bold;
            cursor: pointer;
            border-radius: 5px;
            //color: white;
        }
        .fields{
            justify-content: space-between;
            flex-wrap: wrap;
            margin-left: 20px;
            display: block;
            margin-right: 20px;
        }
        .field{
            width: 200px;
            overflow-x: hidden;
            
        }
        .submit{
            height: auto;
            width: auto;
            border-radius: 2px;
            padding: 5px;
            align-self: center;
            margin-top: 20px;
        }
        input{
            margin-bottom: 15px;
        }
    }
    .document-name{
        max-width: 200px;
        overflow-x: hidden;
        margin-right: 15px;
    }
    .fields{
        width: calc(100%);
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow: hidden;
        gap: 10px 10px;
        .description{
            margin-right: 10px;
            font-weight: bold;
            color: rgb(136, 136, 136);
        }
        .displayFields{
            //padding-left: 10px;
            margin: 20px 5px;

            display: flex;
            min-width: 200px;
            max-width: 620px;
            overflow-y: hidden;
            overflow-x: hidden;
            h4{
                font-size: 18px;
                //color: red;
                //overflow-y: scroll;
                //max-height: 100px;
                //display: inline;
                white-space: pre-line;
                text-align: left;
            }
        }
    }
    .video{
        height: 120px;
        video{
            height: 120px;
        }
        img{
            height: 120px;
            //width: 200px;
            object-fit: contain;
        }
    }
    .edit-buttons{
        position: relative;
    }
    .delete, .edit{
        
        svg{
            margin-top: 1px;
            //position: absolute;
            height: 22px;
            width: 22px;
        }
    }
    .editing{
        justify-content: space-between;
        flex-wrap: wrap;
        margin-left: 20px;
        line-height: 1.5;
        display: block;
        button{
            height: 28px;
            padding: 4px;
            margin: 4px;
        }
        .field{
            width: 200px;
        }
        
    }
    ul{
        float: none;
        li{
            width: auto;
            width: calc(100%-5px) !important;
            background-color: rgb(255, 255, 255);
            border-radius: 5px;
            margin: 10px 0;
            text-decoration: none;
            list-style: none;
            font-size: 16px;
            color: black;
            padding: 10px 4px;
            //display: flex;
            justify-content: space-between;
            align-items: center;
            h2{
                font-size: 24px;
                margin: 0;
                //min-width: 100px;
            }
        }
    }
}
.item-list{
    height: auto;
    line-height: normal;
    h2{
        margin: 0;
        line-height: normal;
        padding: 0;
        height: auto;
    }
}
.custom-button{
    //height: 30px
}


.login-form{
    margin: 40px auto;
    background-color: rgb(219, 219, 219);
    padding: 15px;
    width: 100%;
    max-width: 300px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    label{
        width: 100%;
    }
    input{
        width: 100%;
        height: 30px;
        padding: 2px;
    }
}

@media only screen and (max-width: 700px) {
    .main{
        flex-direction: column;
        padding: 0;
        
    }
    .editor, .selector{
        width: 95%;
        margin: 10px auto;
        padding: 10px;
    }
    .third-section{
        width: calc(100% - 40px);
        margin: 5px;
        padding: 10px;
    }
    .second-section, .logo-section{
        padding: 5px 20px;
    }
}

@media only screen and (max-width: 550px) {
    .logo-section{
        padding: 5px 20px 5px 5px;
        svg{
            height: 100px;
        }
    }
    
    h1{
        font-size: 22px;
    }
    .cms-display{
        display: none;
    }
    
}