/* Works on Firefox */
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap");
* {
  scrollbar-width: thin;
  scrollbar-color: #646464 #aaaaaa;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #aaaaaa;
}

*::-webkit-scrollbar-thumb {
  background-color: #646464;
  border-radius: 20px;
  border: 3px solid #aaaaaa;
}

section {
  padding: 5px;
  margin: 5px 10px;
  height: auto;
}

a {
  text-decoration: none;
}

button {
  background-color: white;
  border-style: none;
  font-weight: 600;
  color: #357cff;
  padding: 6px;
  font-size: 16px;
  border-radius: 5px;
  margin: 4px;
}

button:hover {
  cursor: pointer;
  background-color: #357cff;
  color: white;
}

.logo-section {
  background-color: #357cff;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 5px 35px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: white;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.awd-logo {
  color: white;
}

.awd-logo svg {
  height: 200px;
}

.second-section {
  background-color: #dbdbdb;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 5px 35px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.sign-out {
  cursor: pointer;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  font-family: "roboto";
}

.selector {
  width: 250px;
  background-color: #dbdbdb;
  border-radius: 10px;
}

.selector .collection {
  width: auto;
  margin: 5%;
  padding: 4px 4px;
  margin: 5px 10px;
  color: black;
  font-size: 20px;
  background-color: white;
  border-radius: 5px;
  display: block;
}

.selector .collection:hover {
  border-width: 0;
  border-left-width: 5px;
  border-style: solid;
  border-color: #357cff;
}

.selector .active {
  border-width: 0;
  border-left-width: 5px;
  border-style: solid;
  border-color: #357cff;
}

.third-section {
  width: calc(100% - 120px);
  margin: 30px;
  padding: 30px;
  background-color: #dbdbdb;
  border-radius: 10px;
}

.sample-site {
  background-color: white;
  width: 100%;
  height: 90vh;
  border-width: 0px;
}

.editor {
  width: 100%;
  max-height: 500px;
  overflow-y: scroll;
  background-color: #dbdbdb;
  border-radius: 10px;
  padding: 10px;
}

.editor h1 {
  font-size: 32px;
}

.editor .topbar {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

.editor h2 {
  font-size: 30px;
}

.editor .new-item {
  width: calc(100%-5px);
  background-color: white;
  color: black;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.editor .new-item span {
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 5px;
}

.editor .new-item .fields {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: 20px;
  display: block;
  margin-right: 20px;
}

.editor .new-item .field {
  width: 200px;
  overflow-x: hidden;
}

.editor .new-item .submit {
  height: auto;
  width: auto;
  border-radius: 2px;
  padding: 5px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin-top: 20px;
}

.editor .new-item input {
  margin-bottom: 15px;
}

.editor .document-name {
  max-width: 200px;
  overflow-x: hidden;
  margin-right: 15px;
}

.editor .fields {
  width: calc(100%);
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  gap: 10px 10px;
}

.editor .fields .description {
  margin-right: 10px;
  font-weight: bold;
  color: #888888;
}

.editor .fields .displayFields {
  margin: 20px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-width: 200px;
  max-width: 620px;
  overflow-y: hidden;
  overflow-x: hidden;
}

.editor .fields .displayFields h4 {
  font-size: 18px;
  white-space: pre-line;
  text-align: left;
}

.editor .video {
  height: 120px;
}

.editor .video video {
  height: 120px;
}

.editor .video img {
  height: 120px;
  -o-object-fit: contain;
     object-fit: contain;
}

.editor .edit-buttons {
  position: relative;
}

.editor .delete svg, .editor .edit svg {
  margin-top: 1px;
  height: 22px;
  width: 22px;
}

.editor .editing {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-left: 20px;
  line-height: 1.5;
  display: block;
}

.editor .editing button {
  height: 28px;
  padding: 4px;
  margin: 4px;
}

.editor .editing .field {
  width: 200px;
}

.editor ul {
  float: none;
}

.editor ul li {
  width: auto;
  width: calc(100%-5px) !important;
  background-color: white;
  border-radius: 5px;
  margin: 10px 0;
  text-decoration: none;
  list-style: none;
  font-size: 16px;
  color: black;
  padding: 10px 4px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.editor ul li h2 {
  font-size: 24px;
  margin: 0;
}

.item-list {
  height: auto;
  line-height: normal;
}

.item-list h2 {
  margin: 0;
  line-height: normal;
  padding: 0;
  height: auto;
}

.login-form {
  margin: 40px auto;
  background-color: #dbdbdb;
  padding: 15px;
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.login-form label {
  width: 100%;
}

.login-form input {
  width: 100%;
  height: 30px;
  padding: 2px;
}

@media only screen and (max-width: 700px) {
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0;
  }
  .editor, .selector {
    width: 95%;
    margin: 10px auto;
    padding: 10px;
  }
  .third-section {
    width: calc(100% - 40px);
    margin: 5px;
    padding: 10px;
  }
  .second-section, .logo-section {
    padding: 5px 20px;
  }
}

@media only screen and (max-width: 550px) {
  .logo-section {
    padding: 5px 20px 5px 5px;
  }
  .logo-section svg {
    height: 100px;
  }
  h1 {
    font-size: 22px;
  }
  .cms-display {
    display: none;
  }
}
