$height: 120px;
.nav-bar{
    height: $height;
    width: 100%;
    background-color: red;
    display: flex;
    justify-content: space-between;
}
.nav-links{
    display: flex;
    height: 100%;
    
}
.logo{
    height: 100%;
}
li{
    height: 100%;
    text-align: center;
    width: 150px;
    
    line-height: calc(#{$height} - 10px);
    a{
        color: white;
        font-size: 20px;
    }
}
span{
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}
svg{
    height: calc(#{$height} + 20px);
    width: calc(#{$height} + 20px);
    margin-top: -10px;
}