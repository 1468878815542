.nav-bar {
  height: 120px;
  width: 100%;
  background-color: red;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.nav-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.logo {
  height: 100%;
}

li {
  height: 100%;
  text-align: center;
  width: 150px;
  line-height: calc(120px - 10px);
}

li a {
  color: white;
  font-size: 20px;
}

span {
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

svg {
  height: calc(120px + 20px);
  width: calc(120px + 20px);
  margin-top: -10px;
}
